import HouseIcon from "./HouseIcon";

function Flame({ r, square, gray, opacity }) {
	const bg = gray ? "#E4E4E6" : "#CEF2FF";
	const fill = gray ? "#6F6F6F" : "#079AFF";

	return (
		<HouseIcon r={r} square={square} bg={bg} scale={48} opacity={opacity}>
			<path
				d="M.2484-23.655C-1.9059-22.1026-3.6601-20.0601-4.8692-17.696-6.0784-15.332-6.7078-12.7142-6.7057-10.0588V-9.9515C-6.6922-7.9398-6.3234-6.0085-5.6629-4.2214-5.1264-2.7863-6.3469-1.1702-7.7081-1.8743-10.2382-3.1883-12.3933-5.123-13.9715-7.4972-14.6488-8.5165-16.1442-8.7143-16.8147-7.6917-19.026-4.3251-20.1758-.3722-20.1153 3.6553-20.0547 7.6827-18.7866 11.5992-16.4751 14.8978-14.1636 18.1964-10.9152 20.7252-7.1503 22.1569-3.3855 23.5885.7225 23.857 4.6417 22.9277 8.5609 21.9983 12.1109 19.914 14.8322 16.9443 17.5534 13.9747 19.3206 10.2566 19.9049 6.2713 20.4892 2.2861 19.8639-1.7829 18.1096-5.4087 16.3554-9.0345 13.5532-12.0503 10.0658-14.0656L10.0524-14.0891C6.6861-16.028 4.1013-19.08 2.743-22.7195 2.354-23.759 1.1436-24.2988.2484-23.655ZM2.8636-2.9439C4.445-2.4749 5.8869-1.6244 7.0623-.4672 8.2376.69 9.1105 2.1184 9.604 3.6923 10.0975 5.2662 10.1966 6.9372 9.8925 8.5584 9.5883 10.1795 8.8903 11.7011 7.8599 12.989 6.8295 14.277 5.4982 15.2919 3.9833 15.9444 2.4684 16.5969.8163 16.867-.8274 16.7309-2.4713 16.5948-4.0564 16.0567-5.4434 15.1639-6.8303 14.2711-7.9765 13.0511-8.7811 11.6112-9.4684 10.3874-8.0602 9.2843-6.7023 9.633-4.6462 10.1621-2.4941 10.1966-.4222 9.7336.5434 9.5156.9626 8.4393.6541 7.5005.2192 6.1599-.0015 4.759.0003 3.3496.0003 1.3881.4227-.4728 1.1771-2.1526 1.3143-2.4666 1.5612-2.7199 1.8717-2.865 2.1821-3.01 2.5348-3.0402 2.8636-2.9439Z"
				fill={fill}
			/>
		</HouseIcon>
	);
}

export default Flame;
